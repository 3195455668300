import { Colors, Img } from "@zapier/design-system";
import styled from "styled-components";

type ServiceIconSize = 20 | 30 | 40 | 60 | 80 | 120 | 150;

type ServiceIconConfig = {
  /** The border radius applied to the icon. */
  borderRadius: number;
  /** The iconSize between the icon shell and the icon image. */
  iconSize: number;
  /** The spacing between two icons. Does not apply to the start or end of the list. */
  spacing: number;
};

const ServiceIconConfig: {
  [key in ServiceIconSize]: ServiceIconConfig;
} = {
  20: {
    borderRadius: 3,
    iconSize: 14,
    spacing: 5,
  },
  30: {
    borderRadius: 3,
    iconSize: 20,
    spacing: 5,
  },
  40: {
    borderRadius: 5,
    iconSize: 30,
    spacing: 5,
  },
  60: {
    borderRadius: 8,
    iconSize: 40,
    spacing: 10,
  },
  80: {
    borderRadius: 10,
    iconSize: 60,
    spacing: 20,
  },
  120: {
    borderRadius: 15,
    iconSize: 90,
    spacing: 30,
  },
  150: {
    borderRadius: 18,
    iconSize: 110,
    spacing: 40,
  },
};

type ServiceIconProps = {
  /**
   * Inform screen-readers that they should ignore the image. Image will still be visually displayed.
   *
   * @default false
   */
  ariaHidden?: boolean;
  /**
   * The name of the service whose icon is being displayed (used as alt text for the icon), e.g., "Google Sheets".
   */
  serviceName: string;
  /**
   * This is the `app` field from a ZDL step, e.g. "GoogleMailV2API".
   */
  service: string;
  /**
   * Sets the width and height of the icon (since it is a square), in pixels.
   * The list of possible sizes is finite to enforce consistency across our UI.
   */
  size: ServiceIconSize;
  /**
   * Applies a custom alt attribute to the underlying Img component.
   */
  alt?: string;
  className?: string;
};

const ServiceIconShell = styled.span<{ size: ServiceIconSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.neutral100};
  border: 1px solid ${Colors.neutral300};
  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
    border-radius: ${ServiceIconConfig[size].borderRadius}px;
  `}
`;

/**
 * Renders an icon representing a service (aka app, aka integration).
 */
export const ServiceIcon = (props: ServiceIconProps) => {
  const { ariaHidden, serviceName, size, service, alt, className } = {
    ariaHidden: false,
    ...props,
  };

  const imageUrl = `https://zapier.com/generated/${stripVersion(service)}/128/`;

  const { iconSize } = ServiceIconConfig[size];
  return (
    <ServiceIconShell size={props.size} className={className}>
      <Img
        alt={alt || `${serviceName} logo`}
        ariaHidden={ariaHidden}
        borderRadius={0}
        height={iconSize}
        objectFit="contain"
        src={imageUrl}
        width={iconSize}
      />
    </ServiceIconShell>
  );
};

function stripVersion(key: string | null | undefined) {
  key = key || "";
  return key.split("@")[0];
}

import { ClassNames } from "@emotion/react";
import { Input, type InputProps } from "@zapier/design-system";
import { forwardRef, type Ref } from "react";
import TextareaAutosize, {
  type TextareaAutosizeProps,
} from "react-textarea-autosize";

type AutosizeTextareaRef = HTMLInputElement | HTMLTextAreaElement;

type Props = TextareaAutosizeProps & Omit<InputProps, "children" | "size">;

export const AutosizeTextarea = forwardRef<AutosizeTextareaRef, Props>(
  function AutosizeTextarea(props, ref) {
    return (
      <Input {...props}>
        {(inputProps) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { css, ...inputPropsNoCss } = inputProps;
          return (
            <ClassNames>
              {({ css }) => (
                <TextareaAutosize
                  {...inputPropsNoCss}
                  ref={ref as Ref<HTMLTextAreaElement>}
                  name={props.name}
                  minRows={props.minRows}
                  className={css(inputProps.css, {
                    boxSizing: "border-box",
                    resize: "none",
                    border: "none",
                    ":focus-within": { outline: "none" },
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    transition: [
                      "background-color 0.3s ease 0s",
                      "color 0.3s ease 0s",
                      "box-shadow 0.3s ease 0s",
                      "height 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s",
                    ].join(", "),
                  })}
                  value={inputProps.value}
                  onChange={inputProps.onChange}
                />
              )}
            </ClassNames>
          );
        }}
      </Input>
    );
  }
);

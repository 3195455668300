import { env } from "@/env.mjs";
import { type ZDL } from "@/utils/zdl";
import { Button } from "@zapier/design-system";
import { useCallback, useState } from "react";

const zapierOrigin = (() => {
  const environment = env.NEXT_PUBLIC_VERCEL_ENV;
  if (environment === "development" || environment === "preview") {
    return "https://zapier-staging.com";
  }

  return "https://zapier.com";
})();

export function MakeAZap({ zap }: { zap: ZDL }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleOnClick = useCallback(async () => {
    if (isLoading) {
      return;
    }

    // TODO: wire this up as implicitGood feedback
    // submitFeedback()

    setIsLoading(true);
    setError(undefined);

    try {
      const response = await fetch("/api/make-zap-from-zdl", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ zdl: zap }),
      });

      if (!response.ok) {
        const errorMessage = response.headers
          .get("content-type")
          ?.includes("application/json")
          ? ((await response.json()).error as string)
          : 'Try clicking the "Sync with Zapier Session" button on the top right and trying again.';

        throw new Error(errorMessage);
      }

      const result: { id: string } | null = await response.json();

      if (result && result.id) {
        // TODO: add account_id context;
        // window.location.href = `${zapierOrigin}/editor/${result.id}?context={user.account_id}`;
        window.location.href = `${zapierOrigin}/editor/${result.id}`;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
      setIsLoading(false);
    }
  }, [isLoading, zap]);
  return (
    <>
      <Button onClick={handleOnClick} disabled={isLoading}>
        {isLoading ? "Thinking..." : "Create Zap"}
      </Button>
      {error && <div style={{ color: "red" }}>{error}</div>}
    </>
  );
}

import { Button } from "@zapier/design-system";
import { useState, type FormEventHandler, type ReactNode } from "react";
import styled from "styled-components";
import { AutosizeTextarea } from "@/components/AutosizeTextarea";

// -2/+2 for explicit feedback. -1/+1 for implicit feedback.
const ratingMap = {
  implicitGood: 1,
  implicitBad: -1,
  explicitGood: 2,
  explicitBad: -2,
};

export async function submitFeedback(input: {
  executionId: string;
  rating: keyof typeof ratingMap;
  feedback?: string;
}): Promise<void> {
  try {
    await fetch(`/api/execution/${input.executionId}/submit-feedback`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rating: ratingMap[input.rating],
        feedback: input.feedback,
      }),
    }).then((res) => res.json());
  } catch (e) {
    // Don't surface error to user, just fail silently.
  }
}

const FeedbackWidgetWrapper = styled.div`
  margin-bottom: 30px;
  min-height: 30px;
`;

const FeedbackWidgetInitialPrompt = styled.span`
  i {
    margin-right: 10px;
  }

  button + button {
    margin-left: 5px;
  }

  button:hover {
    cursor: pointer;
  }
`;

const FeedbackWidgetRatedBadForm = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  gap: 10px;
`;

export const FeedbackWidget = (props: { executionId: string }) => {
  const [screen, setScreen] = useState<
    "initial_prompt" | "thanks" | "rated_bad"
  >("initial_prompt");

  const handleSubmitGoodFeedback = () => {
    void submitFeedback({
      executionId: props.executionId,
      rating: "explicitGood",
    });
    setScreen("thanks");
  };

  const handleRatedBad = () => setScreen("rated_bad");

  const handleSubmitBadFeedback: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    // TODO: better type safety for form fields later (prob use a form library)
    // @ts-ignore
    const feedback = e.target.feedback.value;
    if (!feedback) {
      return;
    }

    void submitFeedback({
      executionId: props.executionId,
      rating: "explicitBad",
      feedback,
    });

    setScreen("thanks");
  };

  const screenComponent: Record<typeof screen, ReactNode> = {
    initial_prompt: (
      <FeedbackWidgetInitialPrompt>
        <i>How did we do?</i>
        <button onClick={handleSubmitGoodFeedback}>👍</button>
        <button onClick={handleRatedBad}>👎</button>
      </FeedbackWidgetInitialPrompt>
    ),
    rated_bad: (
      <FeedbackWidgetRatedBadForm onSubmit={handleSubmitBadFeedback}>
        <AutosizeTextarea
          name="feedback"
          placeholder="I expected the guess to have been like this or that..."
          minRows={2}
        />
        <Button type="submit">Submit Feedback</Button>
      </FeedbackWidgetRatedBadForm>
    ),
    thanks: <p>Thank you for your feedback!</p>,
  };

  return (
    <FeedbackWidgetWrapper>{screenComponent[screen]}</FeedbackWidgetWrapper>
  );
};

import {
  Button,
  Colors,
  Dropdown,
  type DropdownProps,
  Field,
  Spinner,
  Text,
} from "@zapier/design-system";
import Head from "next/head";
import Link from "next/link";
import {
  Fragment,
  type ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";
import { useImmer } from "use-immer";
import { type GetServerSidePropsContext } from "next";
import { ServiceIcon } from "@/components/ServiceIcon";
import { getServerAuthSession } from "@/server/auth";
import { type ZDL } from "@/utils/zdl";
import { AutosizeTextarea } from "@/components/AutosizeTextarea";
import { MakeAZap } from "@/components/MakeAZap";
import { Centered } from "@/components/Centered";
import { AuthRequired } from "@/components/AuthRequired";
import { FeedbackWidget } from "@/components/FeedbackWidget";
import { signOut } from "next-auth/react";

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      session: await getServerAuthSession({
        req: context.req,
        res: context.res,
      }),
    },
  };
}

type GuessZapResponse = {
  executionId: string;
  guess: ZDL;
};

type GuessedZap = GuessZapResponse["guess"];
// FIXME the off-spec keys are all optional because they get added during regrounding step. How to improve?
type StepOption = NonNullable<
  GuessedZap["steps"][number]["alternatives"]
>[number];

const Form = styled.form`
  min-width: 500px;
`;

const Results = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 700px;
`;

const SectionSpacer = styled.div`
  width: 150px;
  margin-top: 20px;
  border-bottom: 1px solid #e3e3e3;
  box-shadow: 0 1px 0px #ffffff;
  margin-bottom: 20px;
`;

async function guessZap(input: { prompt: string }): Promise<GuessZapResponse> {
  const result = await fetch("/api/guess-zap", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ prompt: input.prompt, guessMappings: false }),
  }).then((res) => res.json());

  return result;
}

export default function ZapStepGuesserIndex() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [prompt, setPrompt] = useState("");
  const [guessedZap, setGuessedZap] = useState<GuessedZap | undefined>(
    undefined
  );
  const [executionId, setExecutionLogId] = useState<string | undefined>(
    undefined
  );

  const handleSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }

    // TODO: better type safety for form fields later (prob use a form library)
    // @ts-ignore
    if (!prompt) {
      return;
    }

    setIsLoading(true);
    setError(undefined);
    setGuessedZap(undefined);
    setExecutionLogId(undefined);

    try {
      const result = await guessZap({ prompt });
      const zdl = result.guess;
      setGuessedZap(zdl);
      setExecutionLogId(result.executionId);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
    }

    setIsLoading(false);
  }, [isLoading, prompt]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // The important bits...
  const textareaElement = textareaRef.current;
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Enter" && event.metaKey) {
        void handleSubmit();
      }
    };
    if (textareaElement) {
      textareaElement.addEventListener("keydown", listener);
    }
    return () => {
      if (textareaElement) {
        textareaElement.removeEventListener("keydown", listener);
      }
    };
  }, [textareaElement, handleSubmit]);

  return (
    <div>
      <Head>
        <title>Zap Step Guesser</title>
      </Head>
      <AuthRequired>
        <Centered>
          <SyncSessionButton />
          <Header />
          <>
            <p>
              Write a sentence about what you&apos;re trying to do. For example:
            </p>
            <ul>
              <li>
                <i>Save new leads from Facebook Lead Ads to Google Sheets</i>
              </li>
              <li>
                <i>Add all new saved Slack messages to my Todoist app</i>
              </li>
              <li>
                <i>Share new Facebook posts to my Instagram account</i>
              </li>
            </ul>
          </>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit();
            }}
          >
            <Field
              label="What do you want to happen?"
              inputId="prompt"
              renderInput={(inputProps) => (
                <AutosizeTextarea
                  {...inputProps}
                  ref={textareaRef}
                  name="prompt"
                  autoFocus={true}
                  autoComplete="off"
                  value={prompt}
                  minRows={2}
                  onChange={(e) =>
                    setPrompt((e.target as HTMLTextAreaElement).value)
                  }
                />
              )}
            />
            <Button type="submit" disabled={isLoading}>
              Guess the Zap
            </Button>
          </Form>
          <Results>
            {isLoading && <Loading />}
            {error && (
              <Text color="error500">
                {error}. Please try again or contact support.
              </Text>
            )}
            {guessedZap && (
              <>
                <SectionSpacer />
                <h3>Guessed this Zap:</h3>
                {executionId && <FeedbackWidget executionId={executionId} />}
                <GuessedZap initialGuessedZap={guessedZap} />
                <MakeAZap zap={guessedZap} />
              </>
            )}
          </Results>
        </Centered>
      </AuthRequired>
    </div>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 15px;
`;

const Logo = styled(Link)`
  font-family: var(
    --zds-typography-heading-display,
    "Degular Display",
    Helvetica,
    arial,
    sans-serif
  );
  font-size: 34px;
  color: ${Colors.neutral800};

  span {
    color: ${Colors.brand};
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Logo href="/app/zap-step-guesser">
        <span>_zap</span> guesser!
      </Logo>
    </HeaderContainer>
  );
};

const Loading = () => {
  return (
    <Centered>
      <Spinner />
      <i>Thinking...</i>
    </Centered>
  );
};

const ZapSteps = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ZapStep = styled.li`
  border-radius: 5px;
  border: 1px solid ${Colors.neutral800};
  background-color: ${Colors.neutral100};
  opacity: 1;
  transition: opacity 150ms ease-out 0s, box-shadow 150ms ease-out 0s,
    border-color 300ms ease-in-out 0s;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 3px 0px;
  box-sizing: border-box;
`;

const ZapStepHeader = styled.div`
  align-items: center;
  background-color: ${Colors.neutral200};
  border-radius: 5px 5px 0px 0px;
  display: flex;
  padding: 20px;
  transition: background-color 300ms ease-in-out 0s;
  column-gap: 15px;
`;

const AppIcon = styled(ServiceIcon)`
  flex-shrink: 0;
`;

const ZapStepContent = styled.div`
  padding: 20px;
`;

const connectorColor = Colors.neutral600;
const ZapStepConnector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
  position: relative;

  div {
    position: absolute;
    bottom: 0;

    &:before {
      content: "";
      display: block;
      border-right: 2px solid ${connectorColor};
      height: 46px;
      transform: translate(-11px, 11px);
    }

    &:after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-right: 2px solid ${connectorColor};
      border-bottom: 2px solid ${connectorColor};
      transform: rotate(45deg);
      transform-origin: 10px 0px;
    }
  }
`;

export const GuessedZap = (props: { initialGuessedZap: GuessedZap }) => {
  const [guessedZap, setGuessedZap] = useImmer<GuessedZap>(
    props.initialGuessedZap
  );

  const handleAppActionChange = useCallback(
    (stepIndex: number, selectedOption: StepOption) => {
      setGuessedZap((draft) => {
        const stepOptions = draft.steps[stepIndex].alternatives || [];
        const selectedOptionIndex = stepOptions.findIndex(
          (option) =>
            option.action === selectedOption.action &&
            option.app == selectedOption.app
        );
        stepOptions.splice(selectedOptionIndex, 1);
        stepOptions.unshift(selectedOption);
      });
    },
    [setGuessedZap]
  );

  const steps = guessedZap.steps;
  if (!steps) {
    return <p>Sorry, I couldn&apos;t guess the Zap steps.</p>;
  }

  return (
    <ZapSteps>
      {steps.map((step, i) => {
        const initialSelectedItem = {
          type: step.type,
          app: step.app,
          appLabel: step.appLabel,
          action: step.action,
          actionLabel: step.actionLabel,
        };
        const items = [initialSelectedItem, ...(step.alternatives || [])];
        return (
          <Fragment key={i}>
            <ZapStep>
              <ZapStepHeader>
                <Text type="sectionHeader3">{step.title}</Text>
              </ZapStepHeader>
              <ZapStepContent>
                <AppActionDropdown
                  initialSelectedItem={initialSelectedItem}
                  items={items}
                  onChange={(selectedItem) =>
                    handleAppActionChange(i, selectedItem)
                  }
                />
              </ZapStepContent>
            </ZapStep>
            {i < steps.length - 1 && (
              <ZapStepConnector>
                <div />
              </ZapStepConnector>
            )}
          </Fragment>
        );
      })}
    </ZapSteps>
  );
};

const DropdownWrapper = styled.div`
  min-width: 400px;
`;

const AppActionDropdown = (
  props: Pick<DropdownProps, "initialSelectedItem" | "items" | "onChange">
) => {
  const renderIcon = (item: StepOption): ReactNode =>
    !!item && (
      <AppIcon
        ariaHidden={true}
        service={item.app}
        serviceName={item.appLabel}
        size={30}
      />
    );
  return (
    <DropdownWrapper>
      <Dropdown
        ariaLabel="App & Action"
        getLabelForItem={(item: StepOption) =>
          `${item.appLabel} - ${item.actionLabel}`
        }
        menuAriaLabel="List of available app + action combinations"
        placeholder="Choose an action"
        renderIcon={renderIcon}
        {...props}
      />
    </DropdownWrapper>
  );
};

// FIXME: hacky solution to keep session in sync with zapier.com session.
// Useful when your zapier.com session expires, or when you changed accounts, etc.
const StyledSyncSessionButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;
const SyncSessionButton = () => {
  return (
    <StyledSyncSessionButton>
      <button onClick={() => signOut()}>Sync with Zapier Session</button>
    </StyledSyncSessionButton>
  );
};
